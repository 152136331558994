import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const Form = styled.form`
    .input-holder {
        display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
        margin-bottom: 15px;

        input {
            width: 31%;

            margin: 0;
            height: 30px;
            line-height: 15px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            padding: 4px 8px!important;
            font-size: 14px!important;
            background: #f1f1e6;
            min-height: 24px;
            border: 1px solid #cdc8b1;
            font-weight: 400;
            font-family: Open Sans !important;
            color: #9a9683;
        }
    }

    textarea {
        width: 100%;
        height: 200px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        resize: none;
        overflow: auto;
        font-weight: 400;
        color: #9a9683;
        border: 1px solid #cdc8b1;
        background: #f1f1e6;
        min-height: 24px;
        margin: 0;
        font-family: Open Sans !important;
        padding: 4px 8px!important;
        font-size: 14px!important;
        display: block;
        margin-bottom: 24px;
        line-height: 24px;
    }

    button {
        background: #bce623;
        height: 58px;
        margin: 0 15px 0 0;
        padding: 0 32px;
        border: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        -webkit-transition: .8s;
        -moz-transition: .8s;
        -o-transition: .8s;
        transition: .8s;
        font-family: roboto,sans-serif;
        font-size: 23px;
        font-weight: 400;
        text-shadow: none;
        color: #fff;
        display: inline-block;
        line-height: 24px;
        text-align: center;

    &:hover {
        background: #6b5f53;
        -webkit-transition: .4s;
        -moz-transition: .4s;
        -o-transition: .4s;
        transition: .4s;
        color: #fff;
    }
    }
`

const ContactForm = () => (
    <div>
        <h2>
            <FormattedMessage id="ContactForm" />
        </h2>
    </div>
)

export default ContactForm
