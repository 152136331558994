import React from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '600px'
};

const center = {
  lat: 42.561417,
  lng: 27.640178
};

function MapsComponent() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAkoNjbEQxlm6y3Jwl9UURyfJoXG9N_KNc'
  });

  const [map, setMap] = React.useState(null);
  // const intl = useIntl();

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={15}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. * Komfort, Koral, Marina */}
      <Marker position={{ lat: 42.559217, lng: 27.643582 }} label="Comfort" />
      <Marker position={{ lat: 42.562413, lng: 27.639335 }} label="Coral" />
      <Marker position={{ lat: 42.557599, lng: 27.646757 }} label="Marina" />
      <Marker position={{ lat: 42.563943, lng: 27.64147 }} label="Perfect" />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(MapsComponent);
