import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Layout, Container } from '../components/common'
import SEO from '../components/common/SEO'
import Header from '../components/theme/Header'
import Footer from '../components/theme/Footer'
import MapsComponent from '../components/common/Contacts/GoogleMaps'
import ContactInfo from '../components/common/Contacts/ContactInfo'
import ContactForm from '../components/common/Contacts/ContactForm'
import TitleSection from '../components/common/TitleSection'

const MainContentHolder = styled.div`
	.text-form-holder {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin: 20px 0;
	}

	.info-box {
		display: inline-block;
		width: 30%;
	}

	.contact-form-box {
		display: inline-block;
		width: 65%;
	}

	h2 {
		font: normal 45px/55px Open Sans;
		color: #584a3e;
		margin: 12px 36px 12px 0;
		font-weight: 300!important;
	}

	address {
		display: block;
		margin-bottom: 24px;
		font-style: normal;
		line-height: 24px;

		a {
			color: #9fcb00;

			&:hover, &:focus {
				color: #a19677;
			}
		}
	}

	@media screen and (max-width: 800px) {
		.info-box, .contact-form-box {
			display: block;
			width: 100%;
		}
	}
`

const ContactsPage = () => (
	<Layout>
		<React.Fragment>
			<SEO title="contacts" />
			<Header />
			<MainContentHolder className="main-content-holder">
				<TitleSection section="Contacts" />
				<div>
					<MapsComponent />
				</div>
				<div className="text-form-holder">
					<div className="info-box">
						<ContactInfo />
					</div>
					<div className="contact-form-box">
						<ContactForm />
					</div>
				</div>
			</MainContentHolder>
			<Footer />
		</React.Fragment>
	</Layout>
)

export default ContactsPage
